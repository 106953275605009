import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Paper,
  Button,
  CardActionArea,
  Card,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart, saveShippingAddress } from '../actions/cartAction';
import { createOrder } from '../actions/orderAction';
import { CART_REMOVE_SHIPPING_ADDRESS } from '../constants/cartConstant';
import Message from '../components/Message';
// import { connectWallet, purchaseNFT } from '../actions/blockChainAction';

const Shipping = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { itemId } = useParams();

  const [apt, setApt] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');

  const theCart = useSelector((state) => state.theCart);
  const { cartItems, shippingAddress } = theCart;

  const orderCreate = useSelector((state) => state.orderCreate);
  const { error, success: successOrder } = orderCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const walletConnection = useSelector((state) => state.walletConnection);
  const { BLOCKCHAIN, success: successContract } = walletConnection;

  // add to cart
  useEffect(() => {
    if (!cartItems || !cartItems[0]) {
      dispatch(addToCart(itemId));
    }
    if (!cartItems[0]) {
      history.push(`/main/products/${itemId}`);
    }
  }, [cartItems]);

  // shipping
  useEffect(() => {
    dispatch({ type: CART_REMOVE_SHIPPING_ADDRESS });
  }, [apt, city, address]);

  // const handleWallet = async () => {
  //   dispatch(connectWallet());
  // };

  const onSubmit = async (e) => {
    e.preventDefault();
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    // if (BLOCKCHAIN) {
    //   dispatch(purchaseNFT(BLOCKCHAIN.marketPlace, BLOCKCHAIN.factory, 'NFT'));
    //   dispatch(
    //     saveShippingAddress({
    //       apt,
    //       address,
    //       city,
    //     })
    //   );
    // }
  };

  // on Mint
  const onMint = async () => {
    console.log('now');
    // backend
    dispatch(
      createOrder({
        user: userInfo,
        cartItems,
        shippingAddress,
        totalCartPrice: cartItems[0].price,
      })
    );
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ marginTop: 5 }}
    >
      <Paper elevation={3} sx={{ padding: 4, margin: 2 }}>
        <FormControl variant="outlined" sx={{ width: '100%' }}>
          <form onSubmit={onSubmit}>
            <Grid item xs={12}>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    sx={{
                      height: 140,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                    image={cartItems[0] ? cartItems[0].cover : ''}
                    title={cartItems[0] ? cartItems[0].title : ''}
                  />
                  <CardContent
                    sx={{
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    <Typography>
                      Title: {cartItems[0] && cartItems[0].title}
                    </Typography>
                    <Typography>
                      Owner: {cartItems[0] && cartItems[0].owner}
                    </Typography>
                    <Typography>
                      Price: {cartItems[0] && cartItems[0].price}
                    </Typography>
                    <Typography>Shipping Cost: Free</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Typography variant="subtitle1">Shipping Address:</Typography>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item xs={6}>
                <TextField
                  color="secondary"
                  type="text"
                  label="Apt #"
                  value={apt}
                  onChange={(e) => setApt(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  color="secondary"
                  type="text"
                  label="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="secondary"
                type="text"
                label="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 4, width: '100%' }}>
              {!shippingAddress.city ? (
                <Button type="submit" variant="outlined" sx={{ width: '100%' }}>
                  Add My Shipping Address
                </Button>
              ) : shippingAddress.city && !successContract ? (
                <Button
                  // onClick={handleWallet}
                  variant="contained"
                  sx={{ width: '100%', color: 'orange' }}
                >
                  Connect Wallet
                </Button>
              ) : (
                successContract && (
                  <Button
                    onClick={onMint}
                    variant="contained"
                    color="secondary"
                    sx={{ width: '100%' }}
                  >
                    Mint for $ {cartItems[0] && cartItems[0].price}
                  </Button>
                )
              )}
            </Grid>
          </form>
        </FormControl>
      </Paper>
      {error ? (
        <Message variant="outlined" severity="error">
          {error}
        </Message>
      ) : successOrder ? (
        <Message variant="outlined" severity="success">
          Congrats, You created an order, it is ready to be paid!
        </Message>
      ) : null}
    </Grid>
  );
};

export default Shipping;
