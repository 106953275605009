/* eslint-disable camelcase */
import { ethers } from 'ethers';
import { publicApi } from '../apis/base';
import Signature from '../blockchain/Signature';
import LazyFactory from '../build/contracts/artifacts/contracts/LazyFactory.sol/LazyFactory.json';
import {
  BUYER_MINT_AND_REDEEM_FAIL,
  BUYER_MINT_AND_REDEEM_REQUEST,
  BUYER_MINT_AND_REDEEM_SUCCESS,
  DEPLOY_MY_STORE_FAIL,
  DEPLOY_MY_STORE_REQUEST,
  DEPLOY_MY_STORE_SUCCESS,
  SIGN_MY_ITEM_FAIL,
  SIGN_MY_ITEM_REQUEST,
  SIGN_MY_ITEM_SUCCESS,
  STORE_BALANCE_FAIL,
  STORE_BALANCE_REQUEST,
  STORE_BALANCE_SUCCESS,
  STORE_WITHDRAW_FAIL,
  STORE_WITHDRAW_REQUEST,
  STORE_WITHDRAW_SUCCESS,
} from '../constants/lazyFactoryConstant';
import { connectWallet } from './marketPlaceAction';

export const deployMyStore =
  (marketPlaceAddress, storeName) => async (dispatch) => {
    let signerContract;
    let signerFactory;
    try {
      dispatch({ type: DEPLOY_MY_STORE_REQUEST });

      // eslint-disable-next-line no-undef
      await window.ethereum.enable();
      // eslint-disable-next-line no-undef
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();

      const { chainId } = await provider.getNetwork();
      console.log(`chain Id here: ${chainId}`);

      signerFactory = new ethers.ContractFactory(
        LazyFactory.abi,
        LazyFactory.bytecode,
        signer
      );
      console.log('this');

      signerContract = await signerFactory.deploy(
        marketPlaceAddress,
        '1L',
        storeName,
        signer.getAddress()
      );
      await signerContract.deployTransaction.wait(); // loading before confirmed transaction
      console.log('that');

      dispatch({
        type: DEPLOY_MY_STORE_SUCCESS,
        payload: { signerContract, signerFactory },
      });
    } catch (e) {
      console.log({ e });
      console.log('problem deploying: ');
      dispatch({
        type: DEPLOY_MY_STORE_FAIL,
        payload:
          e.response && e.response.data.detail
            ? e.response.data.detail
            : e.message,
      });
    }
  };

export const signMyItem =
  (
    signerContractAddress,
    productId,
    price,
    tokenUri,
    address1,
    address2,
    address3,
    address4,
    address5
  ) =>
  async (dispatch) => {
    let voucher;

    try {
      dispatch({ type: SIGN_MY_ITEM_REQUEST });
      // eslint-disable-next-line no-undef
      await window.ethereum.enable();
      // eslint-disable-next-line no-undef
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const signerAddress = await signer.getAddress();
      const theSellingPrice_BN = ethers.utils.parseUnits(
        price.toString(),
        'ether'
      );

      const signerFactory = new ethers.ContractFactory(
        LazyFactory.abi,
        LazyFactory.bytecode,
        signer
      );

      const addressList = [];
      addressList.push(address1);
      addressList.push(address2);
      addressList.push(address3);
      addressList.push(address4);
      addressList.push(address5);
      const signerContract = await signerFactory.attach(signerContractAddress);

      const theSignature = new Signature({ contract: signerContract, signer });
      console.log(theSellingPrice_BN);

      voucher = await theSignature.signTransaction(
        addressList,
        productId,
        theSellingPrice_BN,
        tokenUri
      );

      dispatch({
        type: SIGN_MY_ITEM_SUCCESS,
        payload: { voucher, signerAddress },
      });
    } catch (e) {
      console.log('problem Signing: ');
      console.log(e);
      dispatch({
        type: SIGN_MY_ITEM_FAIL,
        payload:
          e.response && e.response.data.detail
            ? e.response.data.detail
            : e.message,
      });
    }
  };

export const mintAndRedeem =
  (
    productId,
    signerContractAddress,
    voucher,
    price,
    add1,
    add2,
    add3,
    add4,
    add5
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: BUYER_MINT_AND_REDEEM_REQUEST });
      await dispatch(connectWallet()); // connect wallet to get the address

      // eslint-disable-next-line no-undef
      await window.ethereum.enable();
      // eslint-disable-next-line no-undef
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const redeemer = provider.getSigner();

      // Returns a new instance of the ContractFactory with the same interface and bytecode, but with a different signer.
      // const redeemerFactory = signerFactory.connect(redeemer);
      const redeemerFactory = new ethers.ContractFactory(
        LazyFactory.abi,
        LazyFactory.bytecode,
        redeemer
      );

      // Return an instance of a Contract attached to address. This is the same as using the Contract constructor
      // with address and this the interface and signerOrProvider passed in when creating the ContractFactory.
      const redeemerContract = redeemerFactory.attach(signerContractAddress);
      const redeemerAddress = await redeemer.getAddress();

      // 1 - fetch transaction fee for the product
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await publicApi.get(
        `/market/fees/${price.toString()}`,
        config
      );
      const fee = ethers.utils.parseUnits(
        data.transaction_fee_ether.toString(),
        'ether'
      );

      // 2 - re-create voucher transaction fee for the product
      const theSellingPrice_BN = ethers.utils.parseUnits(
        price.toString(),
        'ether'
      );

      const theVoucher = {
        productId: productId,
        sellingPrice: theSellingPrice_BN,
        tokenUri: voucher.token_Uri,
        content: voucher.content,
        signature: voucher.signature,
        address1: add1,
        address2: add2,
        address3: add3,
        address4: add4,
        address5: add5,
      };

      console.log(theVoucher);

      await redeemerContract.redeem(redeemerAddress, theVoucher, fee, {
        value: theSellingPrice_BN,
      });

      dispatch({
        type: BUYER_MINT_AND_REDEEM_SUCCESS,
        payload: voucher,
      });
    } catch (e) {
      console.log('problem buying: ');
      console.log({ e });
      dispatch({
        type: BUYER_MINT_AND_REDEEM_FAIL,
        payload:
          e.response && e.response.data.detail
            ? e.response.data.detail
            : e.message,
      });
    }
  };

export const fetchStoreBalance = (contractAddress) => async (dispatch) => {
  try {
    dispatch({ type: STORE_BALANCE_REQUEST });
    // eslint-disable-next-line no-undef
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    // const balance = await provider.getBalance("address")
    const balance = await provider.getBalance(contractAddress);
    const balanceInEth = ethers.utils.formatEther(balance);

    dispatch({
      type: STORE_BALANCE_SUCCESS,
      payload: balanceInEth,
    });
  } catch (e) {
    console.log('problem fetching balance: ');
    console.log({ e });
    dispatch({
      type: STORE_BALANCE_FAIL,
      payload:
        e.response && e.response.data.detail
          ? e.response.data.detail
          : e.message,
    });
  }
};

export const storeWithdraw = () => async (dispatch, getState) => {
  try {
    dispatch({ type: STORE_WITHDRAW_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    // eslint-disable-next-line no-undef
    const storeAddress = userInfo.store_address;
    // eslint-disable-next-line no-undef
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const signerFactory = new ethers.ContractFactory(
      LazyFactory.abi,
      LazyFactory.bytecode,
      signer
    );

    const signerContract = await signerFactory.attach(storeAddress);

    const withdraw = await signerContract.withdraw();

    dispatch({
      type: STORE_WITHDRAW_SUCCESS,
      payload: withdraw,
    });
  } catch (e) {
    console.log('problem withdrawing: ');
    console.log({ e });
    dispatch({
      type: STORE_WITHDRAW_FAIL,
      payload:
        e.response && e.response.data.detail
          ? e.response.data.detail
          : e.message,
    });
  }
};

// export const withdraw = async (signerContract) => {
//   try {
//     const provider = new ethers.providers.Web3Provider(window.ethereum);
//     const signer = provider.getSigner();
//     const withraw = await signerContract.withdraw()
//   } catch (e) {
//     console.log('problem withdraw: ');
//     console.log(e.error);
//   }
// }
