export const CHANGE_VERIFY_STEP = 'CHANGE_VERIFY_STEP';
export const CHECK_CONTACT_REQUEST = 'CHECK_CONTACT_REQUEST';
export const CHECK_CONTACT_SUCCESS = 'CHECK_CONTACT_SUCCESS';
export const CHECK_CONTACT_FAIL = 'CHECK_CONTACT_FAIL';
export const CHECK_CONTACT_RESET = 'CHECK_CONTACT_RESET';

export const CHECK_USERNAME_REQUEST = 'CHECK_USERNAME_REQUEST';
export const CHECK_USERNAME_SUCCESS = 'CHECK_USERNAME_SUCCESS';
export const CHECK_USERNAME_FAIL = 'CHECK_USERNAME_FAIL';
export const CHECK_USERNAME_RESET = 'CHECK_USERNAME_RESET';

export const USER_VERIFY_REQUEST = 'USER_VERIFY_REQUEST';
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS';
export const USER_VERIFY_FAIL = 'USER_VERIFY_FAIL';
export const USER_VERIFY_RESET = 'USER_VERIFY_RESET';

export const CODE_VERIFY_REQUEST = 'CODE_VERIFY_REQUEST';
export const CODE_VERIFY_SUCCESS = 'CODE_VERIFY_SUCCESS';
export const CODE_VERIFY_FAIL = 'CODE_VERIFY_FAIL';
export const CODE_VERIFY_RESET = 'CODE_VERIFY_RESET';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const USER_STORE_ADDRESS_REQUEST = 'USER_STORE_ADDRESS_REQUEST';
export const USER_STORE_ADDRESS_SUCCESS = 'USER_STORE_ADDRESS_SUCCESS';
export const USER_STORE_ADDRESS_FAIL = 'USER_STORE_ADDRESS_FAIL';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';
