import React, { useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Paper,
  Box,
  Button,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import Chip from '@material-ui/core/Chip';
import Stack from '@material-ui/core/Stack';
import { fetchProductById } from '../actions/productAction';
import { addToCart } from '../actions/cartAction';
import Message from '../components/Message';
import { PRODUCT_DETAILS_RESET } from '../constants/productConstant';
import ProductTabs from '../components/product/ProductTabs';

const Product = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { itemId } = useParams();

  const theCart = useSelector((state) => state.theCart);
  const { cartItems } = theCart;

  const theProduct = useSelector((state) => state.theProduct);
  const { product, error, loading } = theProduct;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!product.id && itemId) {
      dispatch(fetchProductById(itemId));
    }
    return () => dispatch({ type: PRODUCT_DETAILS_RESET });
  }, [dispatch, itemId]);

  useEffect(() => {
    if (userInfo && cartItems && cartItems[0]) {
      history.push(`shipping/${product.id}`);
    }
  }, [userInfo, cartItems]);

  const onAddToCart = () => {
    if (userInfo) {
      dispatch(addToCart(itemId));
    } else {
      history.push(`/login`);
    }
  };

  const renderElement = () => (
    <Grid container direction="column" justifyContent="flex-start" spacing={2}>
      <Grid item>
        {product.user && (
          <>
            <ImageListItemBar
              title={<span style={{ margin: 20 }}>{product.title}</span>}
              subtitle={
                <span style={{ margin: 20 }}>
                  by: {product.user.firstName} {product.user.lastName}
                </span>
              }
              position="below"
            />
            <Typography>Category: {product.category.name}</Typography>
            <Typography>SubCategory: {product.sub_category.name}</Typography>
          </>
        )}
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <Paper elevation={4}>
          <img
            src={`${product.ipfs_cover}`}
            alt="Art work"
            style={{ maxWidth: '100%', minWidth: '100%', height: 'auto' }}
          />
        </Paper>
      </Grid>
      <Grid item sx={{ maxWidth: '100% !important' }}>
        <Box sx={{ overflowX: 'scroll' }}>
          {product.tags && (
            <Stack direction="row" spacing={1}>
              {product.tags.map((tag) => (
                <Chip
                  key={tag.id}
                  label={tag.name}
                  color="primary"
                  variant="outlined"
                />
              ))}
            </Stack>
          )}
        </Box>
      </Grid>
      <Grid item>{product && <ProductTabs product={product} />}</Grid>
      <Grid item>
        <Button
          variant="contained"
          sx={{ width: '100%', marginBottom: 5 }}
          onClick={onAddToCart}
        >
          Purchase
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Container maxWidth="xs" sx={{ height: '100vh' }}>
      {loading === true ? (
        <>
          <CircularProgress />
        </>
      ) : product ? (
        renderElement()
      ) : error ? (
        <Message variant="outlined" severity="error">
          {error}
        </Message>
      ) : null}
    </Container>
  );
};

export default Product;
