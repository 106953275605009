import axios from 'axios';
import apiUrl from '../env';

export const publicApi = axios.create({
  baseURL: apiUrl,
});

export const gecko = axios.create({
  baseURL: 'https://api.coingecko.com/api/v3',
});
