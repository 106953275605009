export const DEPLOY_MY_STORE_REQUEST = 'DEPLOY_MY_STORE_REQUEST';
export const DEPLOY_MY_STORE_SUCCESS = 'DEPLOY_MY_STORE_SUCCESS';
export const DEPLOY_MY_STORE_FAIL = 'DEPLOY_MY_STORE_FAIL';
export const DEPLOY_MY_STORE_RESET = 'DEPLOY_MY_STORE_RESET';

export const SIGN_MY_ITEM_REQUEST = 'SIGN_MY_ITEM_REQUEST';
export const SIGN_MY_ITEM_SUCCESS = 'SIGN_MY_ITEM_SUCCESS';
export const SIGN_MY_ITEM_FAIL = 'SIGN_MY_ITEM_FAIL';
export const SIGN_MY_ITEM_RESET = 'SIGN_MY_ITEM_RESET';

export const BUYER_MINT_AND_REDEEM_REQUEST = 'BUYER_MINT_AND_REDEEM_REQUEST';
export const BUYER_MINT_AND_REDEEM_SUCCESS = 'BUYER_MINT_AND_REDEEM_SUCCESS';
export const BUYER_MINT_AND_REDEEM_FAIL = 'BUYER_MINT_AND_REDEEM_FAIL';
export const BUYER_MINT_AND_REDEEM_RESET = 'BUYER_MINT_AND_REDEEM_RESET';

export const STORE_BALANCE_REQUEST = 'STORE_BALANCE_REQUEST';
export const STORE_BALANCE_SUCCESS = 'STORE_BALANCE_SUCCESS';
export const STORE_BALANCE_FAIL = 'STORE_BALANCE_FAIL';
export const STORE_BALANCE_RESET = 'STORE_BALANCE_RESET';

export const STORE_WITHDRAW_REQUEST = 'STORE_WITHDRAW_REQUEST';
export const STORE_WITHDRAW_SUCCESS = 'STORE_WITHDRAW_SUCCESS';
export const STORE_WITHDRAW_FAIL = 'STORE_WITHDRAW_FAIL';
export const STORE_WITHDRAW_RESET = 'STORE_WITHDRAW_RESET';
