import { ethers } from 'ethers';
import { publicApi } from '../apis/base';

import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_ADD_REQUEST,
  PRODUCT_ADD_SUCCESS,
  PRODUCT_ADD_FAIL,
  PRODUCT_CATAGORIES_REQUEST,
  PRODUCT_CATAGORIES_SUCCESS,
  PRODUCT_CATAGORIES_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  MY_PRODUCTS_FAIL,
  MY_PRODUCTS_REQUEST,
  MY_PRODUCTS_SUCCESS,
} from '../constants/productConstant';

export const fetchAllProducts =
  (keyword = '') =>
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_LIST_REQUEST });
      const response = await publicApi.get(`/products/${keyword}`);

      dispatch({
        type: PRODUCT_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload:
          e.response && e.response.data.detail
            ? e.response.data.detail
            : e.message,
      });
    }
  };

export const fetchProductById = (itemId) => async (dispatch) => {
  try {
    await dispatch({ type: PRODUCT_DETAILS_REQUEST });
    const response = await publicApi.get(`/products/${itemId}`);

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        e.response && e.response.data.detail
          ? e.response.data.detail
          : e.message,
    });
  }
};

export const fetchProductCategories = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_CATAGORIES_REQUEST });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await publicApi.get(`/products/categories/`, config);

    dispatch({
      type: PRODUCT_CATAGORIES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: PRODUCT_CATAGORIES_FAIL,
      payload:
        e.response && e.response.data.detail
          ? e.response.data.detail
          : e.message,
    });
  }
};

export const addProductToBackEnd =
  (
    title,
    categoryId,
    SubCategoryId,
    // description,
    // identifier,
    // identifierNum,
    // grade,
    // tags,
    price,
    ipfsImageUrl,
    // holderWalletAddress,
    // tokenAddress,
    // factorAddress,
    // tokenId,
    add1,
    add2,
    add3,
    add4,
    add5
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_ADD_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      console.log(add1);

      // eslint-disable-next-line no-undef
      const formData = new FormData();
      formData.append('title', title);
      // formData.append('description', description);
      // formData.append('identifier', identifier);
      // formData.append('identifierNum', identifierNum);
      // formData.append('grade', grade);
      formData.append('categoryId', categoryId);
      formData.append('subCategoryId', SubCategoryId);
      // formData.append('tags', tags);
      formData.append('price', price);
      formData.append('ipfsCover', ipfsImageUrl.toString());
      // formData.append('holderWalletAddress', holderWalletAddress);
      // formData.append('tokenAddress', tokenAddress);
      // formData.append('factoryAddress', factorAddress);
      // formData.append('tokenId', tokenId);
      formData.append('address1', add1);
      formData.append('address2', add2);
      formData.append('address3', add3);
      formData.append('address4', add4);
      formData.append('address5', add5);

      const { data } = await publicApi.post(
        `/products/create/`,
        formData,
        config
      );
      dispatch({
        type: PRODUCT_ADD_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: PRODUCT_ADD_FAIL,
        payload:
          e.response && e.response.data.detail
            ? e.response.data.detail
            : e.message,
      });
    }
  };

export const updateProduct =
  (product, storeAddress, sellerAddress, buyerAddress, voucher, action) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_UPDATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // eslint-disable-next-line no-undef
      const formData = new FormData();

      // Option Sign: update product when sign the product
      if (action === 'Signing') {
        const sellingPrice = ethers.utils.parseUnits(
          product.price.toString(),
          'ether'
        );
        formData.append('signature', voucher.signature);
        formData.append('tokenId', voucher.productId);
        formData.append('sellingPrice', sellingPrice); // ether to wei
        formData.append('tokenUri', voucher.tokenUri);
        formData.append('content', voucher.content);
        formData.append('sellerAddress', sellerAddress);
      }
      // Option Redeem and Mint: update product when mint the product
      else if (action === 'RedeemAndMint') {
        formData.append('sellerAddress', sellerAddress); // flu
        formData.append('buyerAddress', buyerAddress); // flu
        formData.append('storeAddress', storeAddress);
      }
      // Option Add to market: create a market sell
      else if (action === 'AddToMarketPlace') {
        formData.append('sellerAddress', sellerAddress); // seller
      }

      const { data } = await publicApi.put(
        `/products/update/${product.id}/${action}/`,
        formData,
        config
      );

      dispatch({
        type: PRODUCT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: PRODUCT_UPDATE_FAIL,
        payload:
          e.response && e.response.data.detail
            ? e.response.data.detail
            : e.message,
      });
    }
  };

export const fetchMyProducts = () => async (dispatch, getState) => {
  try {
    dispatch({ type: MY_PRODUCTS_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await publicApi.get(`/products/owned/`, config);

    dispatch({
      type: MY_PRODUCTS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: MY_PRODUCTS_FAIL,
      payload:
        e.response && e.response.data.detail
          ? e.response.data.detail
          : e.message,
    });
  }
};
