import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { Button, Grid, Typography, Divider } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import { register } from '../../actions/userAction';
import Message from '../Message';

const EntryForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const redirect = location.search
    ? location.search.split('=')[1]
    : '/main/products';

  const userRegister = useSelector((state) => state.userRegister);
  const { error, userInfo } = userRegister;

  const [emailErr, setEmailErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nickName, setNickName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (userInfo && userInfo.token !== undefined) {
      history.push(redirect);
      history.go();
    }
  }, [history, userInfo, redirect]);

  const handleSubmit = () => {
    dispatch(register(firstName, lastName, nickName, email, password));
  };

  const check = () => {
    if (!email) {
      setEmailErr(true);
    } else {
      setEmailErr(false);
    }

    if (!password) {
      setPassErr(true);
    } else {
      setPassErr(false);
    }
  };

  const handleClick = () => {
    check();
    if (email && password) {
      handleSubmit();
    }
  };

  return (
    <Container maxWidth="xs" sx={{ height: '100vh' }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={12} sx={{ marginTop: 10 }}>
          <Typography variant="subtitle2" align="center">
            Register
          </Typography>
        </Grid>
        <FormControl variant="outlined" sx={{ width: '90%' }}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={6}>
              <TextField
                color="secondary"
                value={firstName}
                type="text"
                onChange={(e) => setFirstName(e.target.value)}
                label="First Name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                color="secondary"
                value={lastName}
                type="text"
                onChange={(e) => setLastName(e.target.value)}
                label="Last Name"
              />
            </Grid>
          </Grid>

          <TextField
            color="secondary"
            value={nickName}
            type="text"
            onChange={(e) => setNickName(e.target.value)}
            label="Nick Name"
          />
          <TextField
            color="secondary"
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            required
            error={emailErr}
          />

          <TextField
            color="secondary"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
            required
            error={passErr}
          />
          <Typography>
            Most contain 8 digits, numbers and special character
          </Typography>
          <Grid item xs={12} sx={{ marginTop: 4, width: '100%' }}>
            <Button
              variant="outlined"
              onClick={handleClick}
              sx={{ width: '100%' }}
            >
              Register
            </Button>
          </Grid>
          {error && (
            <Grid sx={{ marginTop: 2, width: '100%' }}>
              <Message variant="" severity="error">
                {error}
              </Message>
            </Grid>
          )}
        </FormControl>
      </Grid>
      <Divider sx={{ marginTop: 2, marginBottom: 2 }}>
        <Typography variant="subtitle1"> OR </Typography>
      </Divider>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12} sx={{ width: '100%' }}>
          <Button variant="outlined" disabled sx={{ width: '100%' }}>
            Login with Google
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 1, width: '100%' }}>
          <Button variant="outlined" disabled sx={{ width: '100%' }}>
            Login with Facebook
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EntryForm;
