import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Splash() {
  const history = useHistory();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    setTimeout(() => {
      if (userInfo) {
        history.push('/main/products');
      }
      history.push('/login');
    }, 3000);
  }, [userInfo]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        backgroundColor: '#222120',
        minHeight: '100vh',
      }}
    >
      <img
        src="/images/logo.webp"
        style={{ maxWidth: '30%', display: 'block', margin: 'auto' }}
        alt="logo"
      />
    </Grid>
  );
}
