import { useDispatch } from 'react-redux';
import { ethers } from 'ethers';
import { gecko, publicApi } from '../apis/base';
import Royalty from '../blockchain/Royalty';
import MarketPlace from '../build/contracts/artifacts/contracts/MarketPlace.sol/MarketPlace.json';
import {
  DEPLOY_MARKET_PLACE_FAIL,
  DEPLOY_MARKET_PLACE_REQUEST,
  DEPLOY_MARKET_PLACE_SUCCESS,
  MARKET_ADD_FAIL,
  MARKET_ADD_REQUEST,
  MARKET_ADD_SUCCESS,
  MARKET_BALANCE_FAIL,
  MARKET_BALANCE_REQUEST,
  MARKET_BALANCE_SUCCESS,
  MARKET_PLACE_FAIL,
  MARKET_PLACE_REQUEST,
  MARKET_PLACE_SUCCESS,
  WALLET_CONNECT_FAIL,
  WALLET_CONNECT_REQUEST,
  WALLET_CONNECT_SUCCESS,
  MARKET_ETH_PRICE_REQUEST,
  MARKET_ETH_PRICE_FAIL,
  MARKET_ETH_PRICE_SUCCESS,
  TOKEN_ROYALTIES_REQUEST,
  TOKEN_ROYALTIES_SUCCESS,
  TOKEN_ROYALTIES_FAIL,
  MARKET_PURCHASE_REQUEST,
  MARKET_PURCHASE_SUCCESS,
  MARKET_PURCHASE_FAIL,
} from '../constants/marketPlaceConstant';
import { updateProduct } from './productAction';

export const deployMarketPlace = () => async (dispatch, getState) => {
  console.log(Royalty);
  try {
    dispatch({ type: DEPLOY_MARKET_PLACE_REQUEST });

    // eslint-disable-next-line no-undef
    await window.ethereum.enable();
    // eslint-disable-next-line no-undef
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const signer = provider.getSigner();

    const { chainId } = await provider.getNetwork();
    console.log(`chain Id here: ${chainId}`);

    const marketPlaceFactory = new ethers.ContractFactory(
      MarketPlace.abi,
      MarketPlace.bytecode,
      signer
    );

    const marketPlaceContract = await marketPlaceFactory.deploy();
    await marketPlaceContract.deployTransaction.wait(); // loading before confirmed transaction

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('marketPlaceAddress', marketPlaceContract.address);

    const response = await publicApi.put(`/market/deploy/`, formData, config);
    dispatch({
      type: DEPLOY_MARKET_PLACE_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    console.log('problem deploying: ');
    dispatch({
      type: DEPLOY_MARKET_PLACE_FAIL,
      payload:
        e.response && e.response.data.detail
          ? e.response.data.detail
          : e.message,
    });
  }
};

export const fetchMarketPlace = () => async (dispatch, getState) => {
  try {
    dispatch({ type: MARKET_PLACE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const response = await publicApi.get(`/market/`, config);
    dispatch({
      type: MARKET_PLACE_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: MARKET_PLACE_FAIL,
      payload:
        e.response && e.response.data.detail
          ? e.response.data.detail
          : e.message,
    });
  }
};

export const connectWallet = () => async (dispatch) => {
  try {
    dispatch({ type: WALLET_CONNECT_REQUEST });

    // eslint-disable-next-line no-undef
    await window.ethereum.enable();
    // eslint-disable-next-line no-undef
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const signer = provider.getSigner();
    const walletAddress = await signer.getAddress();

    dispatch({
      type: WALLET_CONNECT_SUCCESS,
      payload: walletAddress,
    });
  } catch (e) {
    dispatch({
      type: WALLET_CONNECT_FAIL,
      payload:
        e.response && e.response.data.detail
          ? e.response.data.detail
          : e.message,
    });
  }
};

export const fetchMarketBalance = (contractAddress) => async (dispatch) => {
  try {
    dispatch({ type: MARKET_BALANCE_REQUEST });
    // eslint-disable-next-line no-undef
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    // const balance = await provider.getBalance("address")
    const balance = await provider.getBalance(contractAddress);
    console.log(balance);
    const balanceInEth = ethers.utils.formatEther(balance);

    dispatch({
      type: MARKET_BALANCE_SUCCESS,
      payload: balanceInEth,
    });
  } catch (e) {
    console.log('problem buying: ');
    console.log({ e });
    dispatch({
      type: MARKET_BALANCE_FAIL,
      payload:
        e.response && e.response.data.detail
          ? e.response.data.detail
          : e.message,
    });
  }
};

// export const fetchRoyaltyList = (marketAddress) => async (dispatch) => {
//   try {
//     dispatch({ type: MARKET_ROYALTY_LIST_REQUEST });
//     // eslint-disable-next-line no-undef
//     const provider = new ethers.providers.Web3Provider(window.ethereum);
//     const signer = provider.getSigner();

//     const marketPlaceFactory = new ethers.ContractFactory(
//       MarketPlace.abi,
//       MarketPlace.bytecode,
//       signer
//     );
//     const marketPlaceContract = await marketPlaceFactory.attach(marketAddress);
//     const royaltyList = await marketPlaceContract.fetchRoyalties();

//     dispatch({
//       type: MARKET_ROYALTY_LIST_SUCCESS,
//       payload: royaltyList,
//     });
//   } catch (e) {
//     console.log('problem fetching royalty list ');
//     console.log({ e });
//     dispatch({
//       type: MARKET_ROYALTY_LIST_FAIL,
//       payload:
//         e.response && e.response.data.detail
//           ? e.response.data.detail
//           : e.message,
//     });
//   }
// };

// address lazyFactoryAddress,
// uint256 tokenId,
// uint256 sellingPrice,
// Royalty[] memory newRoyaltyList
// after mint we create the item in marketPlace
export const createMarketSell =
  (product, marketAddress, storeAddress, tokenId, sellingPrice) =>
  async (dispatch, getState) => {
    console.log(marketAddress, storeAddress, tokenId, sellingPrice);
    try {
      dispatch({ type: MARKET_ADD_REQUEST });
      // eslint-disable-next-line no-undef
      await window.ethereum.enable();
      // eslint-disable-next-line no-undef
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const signerAddress = await signer.getAddress();

      const marketPlaceFactory = new ethers.ContractFactory(
        MarketPlace.abi,
        MarketPlace.bytecode,
        signer
      );

      const marketPlaceContract = await marketPlaceFactory.attach(
        marketAddress
      );

      // eslint-disable-next-line camelcase
      const theSellingPrice_BN = ethers.utils.parseUnits(
        sellingPrice.toString(),
        'ether'
      );

      const transaction = await marketPlaceContract.createMarketSell(
        storeAddress,
        tokenId,
        theSellingPrice_BN
        // newRoyaltyList // Royalty[] memory array
      );

      await transaction.wait();

      const marketItemCurrent = await marketPlaceContract.fetchCurrentId();

      dispatch(
        updateProduct(
          product,
          null,
          signerAddress,
          null,
          null,
          'AddToMarketPlace'
        )
      );
      console.log(null, null, signerAddress, null, null, 'AddToMarketPlace');
      dispatch({
        type: MARKET_ADD_SUCCESS,
        payload: { transaction, marketItemCurrent },
      });
    } catch (e) {
      console.log('problem adding market item');
      console.log({ e });

      dispatch({
        type: MARKET_ADD_FAIL,
        payload:
          e.response && e.response.data.detail
            ? e.response.data.detail
            : e.message,
      });
    }
  };

// smart-contract interface is used here
export const purchaseNFT =
  (lazyFactoryAddress, marketAddress, product) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_PURCHASE_REQUEST });
      const tokenId = product.id;
      // eslint-disable-next-line no-undef
      await window.ethereum.enable();
      // eslint-disable-next-line no-undef
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const signerAddress = await signer.getAddress();

      const marketPlaceFactory = new ethers.ContractFactory(
        MarketPlace.abi,
        MarketPlace.bytecode,
        signer
      );

      const marketPlaceContract = await marketPlaceFactory.attach(
        marketAddress
      );

      // 1 - fetch transaction fee for the product
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await publicApi.get(
        `/market/fees/${product.price.toString()}`,
        config
      );
      // console.log(tokenId);
      // 2 - fetch current token's royalties & FLU
      const currentRoyaltyList = await marketPlaceContract.fetchTokenRoyalties(
        tokenId
      );
      const FluAddress = await marketPlaceContract.fetchFLU(
        parseInt(tokenId, 10)
      );
      console.log('current Royalty List for this token');
      console.log(currentRoyaltyList);

      // 3 - get all users level using token royalty users
      const payload = { userList: currentRoyaltyList };
      const levelList = await publicApi.post(`/users/level/`, payload, config);
      console.log('levelList.data');
      console.log(levelList.data);

      // 4 - update the list
      const royalty = new Royalty({
        tokenId,
        signerAddress,
      });

      const newRoyaltyList = await royalty.createList(
        marketAddress,
        'addToMarket',
        ethers,
        data.flu_fee_allocation,
        data.chain_fee_allocation,
        data.transaction_fee_ether,
        FluAddress,
        levelList.data,
        currentRoyaltyList
      );

      const itemId = await marketPlaceContract.fetchMarketItemId(tokenId);

      const newSellingPrice = ethers.utils.parseUnits(
        product.price.toString(),
        'ether'
      );
      console.log('data');
      console.log(data);

      const transaction = await marketPlaceContract.marketItemPurchase(
        lazyFactoryAddress,
        lazyFactoryAddress,
        parseInt(itemId, 10),
        newRoyaltyList,
        0,
        { value: newSellingPrice }
      );
      await transaction.wait();

      dispatch({
        type: MARKET_PURCHASE_SUCCESS,
        payload: transaction,
      });
    } catch (e) {
      console.log('problem purchasing');
      console.log({ e });
      dispatch({
        type: MARKET_PURCHASE_FAIL,
        payload:
          e.response && e.response.data.detail
            ? e.response.data.detail
            : e.message,
      });
    }
  };

export const fetchEthPrice = () => async (dispatch) => {
  try {
    dispatch({ type: MARKET_ETH_PRICE_REQUEST });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const { data } = await gecko.get(
      `/simple/price?ids=ethereum&vs_currencies=cad`,
      config
    );

    dispatch({
      type: MARKET_ETH_PRICE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: MARKET_ETH_PRICE_FAIL,
      payload:
        e.response && e.response.data.detail
          ? e.response.data.detail
          : e.message,
    });
  }
};

// // smart-contract interface is used here
// export const fetchMyPurchasedNFT = (marketPlace) => async (dispatch) => {
//   try {
//     dispatch({ type: MY_PURCHASED_NFT_REQUEST });

//     const data = await marketPlace.fetchMyPurchasedNFTs();

//     dispatch({
//       type: MY_PURCHASED_NFT_SUCCESS,
//       payload: data,
//     });
//   } catch (e) {
//     dispatch({
//       type: MY_PURCHASED_NFT_FAIL,
//       payload:
//         e.response && e.response.data.detail
//           ? e.response.data.detail
//           : e.message,
//     });
//   }
// };

// // smart-contract interface is used here
// export const fetchMyCreatedNFTs = (marketPlace) => async (dispatch) => {
//   try {
//     dispatch({ type: MY_PURCHASED_NFT_REQUEST });

//     const data = await marketPlace.fetchMyPurchasedNFTs();

//     dispatch({
//       type: MY_PURCHASED_NFT_SUCCESS,
//       payload: data,
//     });
//   } catch (e) {
//     dispatch({
//       type: MY_PURCHASED_NFT_FAIL,
//       payload:
//         e.response && e.response.data.detail
//           ? e.response.data.detail
//           : e.message,
//     });
//   }
// };

export const fetchRoyalties = (marketAddress, tokenId) => async (dispatch) => {
  try {
    dispatch({ type: TOKEN_ROYALTIES_REQUEST });
    // eslint-disable-next-line no-undef
    await window.ethereum.enable();
    // eslint-disable-next-line no-undef
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const marketPlaceFactory = new ethers.ContractFactory(
      MarketPlace.abi,
      MarketPlace.bytecode,
      signer
    );

    const marketPlaceContract = marketPlaceFactory.attach(marketAddress);

    // 2 - fetch current token's royalties & FLU
    const currentRoyaltyList = await marketPlaceContract.fetchTokenRoyalties(
      tokenId
    );
    dispatch({
      type: TOKEN_ROYALTIES_SUCCESS,
      payload: currentRoyaltyList,
    });
  } catch (e) {
    console.log('problem fetching royalties');
    dispatch({
      type: TOKEN_ROYALTIES_FAIL,
      payload:
        e.response && e.response.data.detail
          ? e.response.data.detail
          : e.message,
    });
  }
};

// smart-contract interface is used here
// export const fetchAvailableCollectible = (marketPlace) => async (dispatch) => {
//   try {
//     dispatch({ type: NFT_LIST_REQUEST });
//     const data = await marketPlace.fetchMarketItems();

//     dispatch({
//       type: NFT_LIST_SUCCESS,
//       payload: data,
//     });
//   } catch (e) {
//     dispatch({
//       type: NFT_LIST_FAIL,
//       payload:
//         e.response && e.response.data.detail
//           ? e.response.data.detail
//           : e.message,
//     });
//   }
// };
