/* eslint-disable no-underscore-dangle */
// These constants must match the ones used in the smart contract.
const SIGNING_DOMAIN_NAME = 'LazyNFT';
const SIGNING_DOMAIN_VERSION = '1';

class Signature {
  constructor({ contract, signer }) {
    this.contract = contract;
    this.signer = signer;
  }

  // design your domain separator
  async designDomain() {
    if (this.domainData != null) {
      return this.domainData;
    }
    const chainId = await this.contract.getChainID();
    // console.log(`chainId: ${chainId.toString()}`);

    this.domainData = {
      name: SIGNING_DOMAIN_NAME,
      version: SIGNING_DOMAIN_VERSION,
      verifyingContract: this.contract.address,
      chainId,
      // the address of the contract that will verify the signature. The user-agent may do contract specific phishing prevention.
    };
    return this.domainData;
  }

  async signTransaction(addressList, productId, sellingPrice, tokenUri) {
    const domain = await this.designDomain();
    // define your data types
    const types = {
      Voucher: [
        { name: 'productId', type: 'uint256' },
        { name: 'sellingPrice', type: 'uint256' },
        { name: 'tokenUri', type: 'string' },
        { name: 'content', type: 'string' },
        { name: 'address1', type: 'address' },
        { name: 'address2', type: 'address' },
        { name: 'address3', type: 'address' },
        { name: 'address4', type: 'address' },
        { name: 'address5', type: 'address' },
      ],
    };

    // eslint-disable-next-line radix
    const theId = parseInt(productId);
    // the data to sign / signature will be added to our solidity struct
    const voucher = {
      productId: theId,
      sellingPrice,
      tokenUri,
      content: 'You are signing this item to be on sale on market!',
      address1: addressList[0],
      address2: addressList[1],
      address3: addressList[2],
      address4: addressList[3],
      address5: addressList[4],
    };

    // console.log(domain, types, voucher);

    // signer._signTypedData(domain, types, value) =>  returns a raw signature
    const signature = await this.signer._signTypedData(domain, types, voucher);

    return {
      ...voucher,
      signature,
    };
  }
}

export default Signature;
