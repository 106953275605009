import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import EntryForm from '../../components/register/EntryForm';

const Register = () => {
  const verifyStep = useSelector((state) => state.verifyStep);
  const { step } = verifyStep;

  const switchComponent = () => {
    switch (step) {
      case 'EntryForm':
        return <EntryForm />;
      case 'VerifyCodeForm':
        return <div>Verify</div>;
      default:
        return <EntryForm />;
    }
  };

  return <Grid>{switchComponent()}</Grid>;
};

export default Register;
