/* eslint-disable no-case-declarations */
import {
  CART_ADD_REQUEST,
  CART_ADD_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_REMOVE_ITEMS,
  CART_ADD_FAIL,
  CART_REMOVE_SHIPPING_ADDRESS,
} from '../constants/cartConstant';

export default (state = { cartItems: [], shippingAddress: {} }, action) => {
  switch (action.type) {
    case CART_ADD_REQUEST:
      return { loadingCart: true, cartItems: [], shippingAddress: {} };
    case CART_ADD_ITEM:
      const item = action.payload;
      const existItem = state.cartItems.find(
        (x) => x.productId === item.productId
      );
      if (existItem) {
        return {
          ...state,
          loadingCart: false,
          cartItems: state.cartItems.map((x) =>
            x.productId === existItem.productId ? item : x
          ),
        };
      }
      return {
        ...state,
        loadingCart: false,
        cartItems: [...state.cartItems, item],
      };

    case CART_SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
      };

    case CART_REMOVE_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: {},
      };

    case CART_REMOVE_ITEMS:
      return { cartItems: [], shippingAddress: state.shippingAddress };

    case CART_ADD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
