export const MY_PRODUCTS_REQUEST = 'MY_PRODUCTS_REQUEST';
export const MY_PRODUCTS_SUCCESS = 'MY_PRODUCTS_SUCCESS';
export const MY_PRODUCTS_FAIL = 'MY_PRODUCTS_FAIL';
export const MY_PRODUCTS_RESET = 'MY_PRODUCTS_RESET';

export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';
export const PRODUCT_LIST_RESET = 'PRODUCT_LIST_RESET';

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST';
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS';
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL';
export const PRODUCT_DETAILS_RESET = 'PRODUCT_DETAILS_RESET';

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL';
export const PRODUCT_DELETE_RESET = 'PRODUCT_DELETE_RESET';

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL';
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET';

export const PRODUCT_ADD_REQUEST = 'PRODUCT_ADD_REQUEST';
export const PRODUCT_ADD_SUCCESS = 'PRODUCT_ADD_SUCCESS';
export const PRODUCT_ADD_FAIL = 'PRODUCT_ADD_FAIL';
export const PRODUCT_ADD_RESET = 'PRODUCT_ADD_RESET';

export const PRODUCT_SEARCH_REQUEST = 'PRODUCT_SEARCH_REQUEST';
export const PRODUCT_SEARCH_SUCCESS = 'PRODUCT_SEARCH_SUCCESS';
export const PRODUCT_SEARCH_FAIL = 'PRODUCT_SEARCH_FAIL';
export const PRODUCT_SEARCH_RESET = 'PRODUCT_SEARCH_RESET';

export const UPLOAD_PRODUCT_IMAGE_REQUEST = 'UPLOAD_PRODUCT_IMAGE_REQUEST';
export const UPLOAD_PRODUCT_IMAGE_SUCCESS = 'UPLOAD_PRODUCT_IMAGE_SUCCESS';
export const UPLOAD_PRODUCT_IMAGE_FAIL = 'UPLOAD_PRODUCT_IMAGE_FAIL';

export const PRODUCT_CATAGORIES_REQUEST = 'PRODUCT_CATAGORIES_REQUEST';
export const PRODUCT_CATAGORIES_SUCCESS = 'PRODUCT_CATAGORIES_SUCCESS';
export const PRODUCT_CATAGORIES_FAIL = 'PRODUCT_CATAGORIES_FAIL';

export const PRODUCT_SUB_CATAGORIES_REQUEST = 'PRODUCT_SUB_CATAGORIES_REQUEST';
export const PRODUCT_SUB_CATAGORIES_SUCCESS = 'PRODUCT_SUB_CATAGORIES_SUCCESS';
export const PRODUCT_SUB_CATAGORIES_FAIL = 'PRODUCT_SUB_CATAGORIES_FAIL';
