import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { PRODUCT_ADD_RESET } from '../constants/productConstant';

export default function FixedBottomNavigation() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [value, setValue] = useState();

  // clean up when we go to add product
  // successAddedMarket
  useEffect(() => {
    dispatch({ type: PRODUCT_ADD_RESET });
    // dispatch({ type: NFT_MINT_RESET });
    // dispatch({ type: MARKET_ADD_RESET });
  }, []);

  useEffect(() => {
    if (value === 'home') {
      history.push('/main/products');
    } else if (value === 'add') {
      history.push('/main/add');
    } else if (value === 'notifications') {
      history.push('/main/notifications');
    } else if (value === 'profile') {
      history.push('/main/profile');
    }
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ pb: 7 }}>
      <CssBaseline />
      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation value={value} onChange={handleChange}>
          <BottomNavigationAction
            label="Home"
            value="home"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            label="Add Item"
            value="add"
            icon={<AddBoxOutlinedIcon />}
          />
          <BottomNavigationAction
            label="Notifications"
            value="notifications"
            icon={<NotificationsIcon />}
          />
          <BottomNavigationAction
            label="Profile"
            value="profile"
            icon={<PersonIcon />}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
