export const CART_ADD_REQUEST = 'CART_ADD_REQUEST';
export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_ADD_FAIL = 'CART_ADD_FAIL';

export const CART_SAVE_SHIPPING_ADDRESS = 'CART_SAVE_SHIPPING_ADDRESS';
export const CART_REMOVE_SHIPPING_ADDRESS = 'CART_REMOVE_SHIPPING_ADDRESS';

export const CART_REMOVE_ITEMS = 'CART_REMOVE_ITEMS';

// export const CHANGE_CART_STEP = 'CHANGE_CART_STEP';

// export const CART_SAVE_PAYMENT_METHOD = 'CART_SAVE_PAYMENT_METHOD';
