import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { Button, Grid, Typography, Divider } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { styled } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { login } from '../../actions/userAction';
import Message from '../../components/Message';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#8338ec',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 20,
    },
    '&.Mui-focused fieldset': {
      borderColor: '#8338ec',
    },
  },
});

const intro = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userLogin = useSelector((state) => state.userLogin);
  const { error, success, userInfo } = userLogin;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (success || userInfo) {
      history.push('/main/products');
    }
  }, [success]);
  const handleClick = () => {
    dispatch(login(username, password));
  };

  return (
    <Container maxWidth="xs" sx={{ height: '100vh' }}>
      <Grid container direction="column" alignItems="center">
        <Grid item xs sx={{ marginTop: 10 }}>
          <Typography variant="subtitle2">Login</Typography>
        </Grid>
        <Grid item sx={{ marginTop: 4, width: '90%' }}>
          <CssTextField
            id="username-login"
            type="email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            label="username"
            variant="outlined"
            fullWidth
            sx={{ borderRadius: '10px' }}
            required
          />
        </Grid>
        <Grid item xs={12} sx={{ width: '90%' }}>
          <TextField
            color="secondary"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
            variant="outlined"
            fullWidth
            required
          />
          <Typography sx={{ direction: 'rtl' }}>?Forgot Password</Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 4 }}>
          <Button variant="outlined" onClick={handleClick}>
            Login
          </Button>
        </Grid>
        {error && (
          <Grid sx={{ marginTop: 2 }}>
            <Message variant="" severity="error">
              {error}
            </Message>
          </Grid>
        )}
      </Grid>
      <Divider sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="subtitle1"> OR </Typography>
      </Divider>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12} sx={{ width: '100%' }}>
          <Button variant="outlined" disabled sx={{ width: '100%' }}>
            Login with Google
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 1, width: '100%' }}>
          <Button variant="outlined" disabled sx={{ width: '100%' }}>
            Login with Facebook
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Typography>
            Don&apos;t have an account? <Link to="/register">Register</Link>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default intro;
