import { publicApi } from '../apis/base';
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_STORE_ADDRESS_REQUEST,
  USER_STORE_ADDRESS_SUCCESS,
  USER_STORE_ADDRESS_FAIL,
  USER_LOGOUT,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
} from '../constants/userConstant';

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.set('email', username);
    formData.set('password', password);
    const { data } = await publicApi.post('/users/login/', formData, {
      config,
    });
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    // eslint-disable-next-line no-undef
    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (e) {
    // check for generic and custom message to return using ternary statement
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        e.response && e.response.data.detail
          ? e.response.data.detail
          : e.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  dispatch({ type: USER_LOGOUT });
  // eslint-disable-next-line no-undef
  localStorage.clear();
};

export const register =
  (firstName, lastName, nickName, email, password) => async (dispatch) => {
    try {
      // eslint-disable-next-line no-undef
      const formData = new FormData();
      formData.set('firstName', firstName);
      formData.set('lastName', lastName);
      formData.set('nickName', nickName);
      formData.set('email', email);
      formData.set('password', password);

      dispatch({ type: USER_REGISTER_REQUEST });
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      };
      const { data } = await publicApi.post('/users/register/', formData, {
        config,
      });
      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });
      // eslint-disable-next-line no-undef
      localStorage.setItem('userInfo', JSON.stringify(data));

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });
      // eslint-disable-next-line no-undef
      localStorage.removeItem('localVerifyInfo');
    } catch (e) {
      // check for generic and custom message to return using ternary statement
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          e.response && e.response.data.detail
            ? e.response.data.detail
            : e.message,
      });
    }
  };

export const updateUserStore = (storeAddress) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_STORE_ADDRESS_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.set('storeAddress', storeAddress);

    const { data } = await publicApi.put(
      `/users/store/update/`,
      formData,
      config
    );

    dispatch({
      type: USER_STORE_ADDRESS_SUCCESS,
      payload: data,
    });
    // login the user with new data and update local storage
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
  } catch (e) {
    // check for generic and custom message to return using ternary statement
    dispatch({
      type: USER_STORE_ADDRESS_FAIL,
      payload:
        e.response && e.response.data.detail
          ? e.response.data.detail
          : e.message,
    });
  }
};

export const fetchPayeeByAddress =
  (walletAddress) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_DETAILS_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await publicApi.get(`users/${walletAddress}/`, config);

      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (e) {
      // check for generic and custom message to return using ternary statement
      dispatch({
        type: USER_DETAILS_FAIL,
        payload:
          e.response && e.response.data.detail
            ? e.response.data.detail
            : e.message,
      });
    }
  };
