import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function productTabs({ product }) {
  const [value, setValue] = useState('1');
  console.log('huh');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Profile" value="1" />
            <Tab label="Description" value="2" />
            <Tab label="Companies" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item>
              <Typography variant="subtitle2">{`Identifier: ${product.identifier}`}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">{`Grade: ${product.grade}`}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">{`Price: $ ${product.price}`}</Typography>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">{product.description}</TabPanel>
        <TabPanel value="3">
          <Typography variant="subtitle2">{product.grading_company}</Typography>
        </TabPanel>
      </TabContext>
    </Box>
  );
}

productTabs.propTypes = {
  product: PropTypes.object.isRequired,
  description: PropTypes.string,
};

productTabs.defaultProps = {
  description: '',
};
