import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import AppBarTop from '../components/AppBarTop';
import ProductsList from '../components/mainComponents/ProductsList';
import AppBarBottom from '../components/AppBarBottom';
import Notifications from '../components/mainComponents/Notifications';
import AddProduct from '../components/mainComponents/AddProduct';
import Profile from '../components/mainComponents/Profile';
import { cleanLocalCart } from '../actions/cartAction';

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    }
  }, [userInfo]);

  useEffect(() => {
    dispatch(cleanLocalCart());
  }, []);

  const path = location.pathname;
  console.log(path);
  return (
    <Grid container>
      {path === '/main/add' ? (
        <>
          <AddProduct />
          <AppBarBottom />
        </>
      ) : path === '/main/notifications' ? (
        <>
          <Notifications />
          <AppBarBottom />
        </>
      ) : path === '/main/profile' ? (
        <>
          <Profile />
          <AppBarBottom />
        </>
      ) : (
        <>
          <AppBarTop />
          <ProductsList />
          <AppBarBottom />
        </>
      )}
    </Grid>
  );
};
export default Home;
