export const DEPLOY_MARKET_PLACE_REQUEST = 'DEPLOY_MARKET_PLACE_REQUEST';
export const DEPLOY_MARKET_PLACE_SUCCESS = 'DEPLOY_MARKET_PLACE_SUCCESS';
export const DEPLOY_MARKET_PLACE_FAIL = 'DEPLOY_MARKET_PLACE_FAIL';

export const MARKET_PLACE_REQUEST = 'MARKET_PLACE_REQUEST';
export const MARKET_PLACE_SUCCESS = 'MARKET_PLACE_SUCCESS';
export const MARKET_PLACE_FAIL = 'MARKET_PLACE_FAIL';

export const WALLET_CONNECT_REQUEST = 'WALLET_CONNECT_REQUEST';
export const WALLET_CONNECT_SUCCESS = 'WALLET_CONNECT_SUCCESS';
export const WALLET_CONNECT_FAIL = 'WALLET_CONNECT_FAIL';

export const MARKET_BALANCE_REQUEST = 'MARKET_BALANCE_REQUEST';
export const MARKET_BALANCE_SUCCESS = 'MARKET_BALANCE_SUCCESS';
export const MARKET_BALANCE_FAIL = 'MARKET_BALANCE_FAIL';
export const MARKET_BALANCE_RESET = 'MARKET_BALANCE_RESET';

// export const NO_WALLET_CONNECT_REQUEST = 'NO_WALLET_CONNECT_REQUEST';
// export const NO_WALLET_CONNECT_SUCCESS = 'NO_WALLET_CONNECT_SUCCESS';
// export const NO_WALLET_CONNECT_FAIL = 'NO_WALLET_CONNECT_FAIL';

// export const MARKET_MINT_REQUEST = 'MARKET_MINT_REQUEST';
// export const MARKET_MINT_SUCCESS = 'MARKET_MINT_SUCCESS';
// export const MARKET_MINT_FAIL = 'MARKET_MINT_FAIL';
// export const MARKET_MINT_RESET = 'MARKET_MINT_RESET';

// after mint we create the item in marketPlace
export const MARKET_ADD_REQUEST = 'MARKET_ADD_REQUEST';
export const MARKET_ADD_SUCCESS = 'MARKET_ADD_SUCCESS';
export const MARKET_ADD_FAIL = 'MARKET_ADD_FAIL';
export const MARKET_ADD_RESET = 'MARKET_ADD_RESET';

export const MARKET_ROYALTY_LIST_REQUEST = 'MARKET_ROYALTY_LIST_REQUEST';
export const MARKET_ROYALTY_LIST_SUCCESS = 'MARKET_ROYALTY_LIST_SUCCESS';
export const MARKET_ROYALTY_LIST_FAIL = 'MARKET_ROYALTY_LIST_FAIL';
export const MARKET_ROYALTY_LIST_RESET = 'MARKET_ROYALTY_LIST_RESET';

export const MARKET_ETH_PRICE_REQUEST = 'MARKET_ETH_PRICE_REQUEST';
export const MARKET_ETH_PRICE_SUCCESS = 'MARKET_ETH_PRICE_SUCCESS';
export const MARKET_ETH_PRICE_FAIL = 'MARKET_ETH_PRICE_FAIL';
export const MARKET_ETH_PRICE_RESET = 'MARKET_ETH_PRICE_RESET';

export const MARKET_LIST_REQUEST = 'MARKET_LIST_REQUEST';
export const MARKET_LIST_SUCCESS = 'MARKET_LIST_SUCCESS';
export const MARKET_LIST_FAIL = 'MARKET_LIST_FAIL';
export const MARKET_LIST_RESET = 'MARKET_LIST_RESET';

export const MARKET_PURCHASE_REQUEST = 'MARKET_PURCHASE_REQUEST';
export const MARKET_PURCHASE_SUCCESS = 'MARKET_PURCHASE_SUCCESS';
export const MARKET_PURCHASE_FAIL = 'MARKET_PURCHASE_FAIL';

export const TOKEN_ROYALTIES_REQUEST = 'TOKEN_ROYALTIES_REQUEST';
export const TOKEN_ROYALTIES_SUCCESS = 'TOKEN_ROYALTIES_SUCCESS';
export const TOKEN_ROYALTIES_FAIL = 'TOKEN_ROYALTIES_FAIL';
// export const MY_PURCHASED_MARKET_REQUEST = 'MY_PURCHASED_MARKET_REQUEST';
// export const MY_PURCHASED_MARKET_SUCCESS = 'MY_PURCHASED_MARKET_SUCCESS';
// export const MY_PURCHASED_MARKET_FAIL = 'MY_PURCHASED_MARKET_FAIL';
