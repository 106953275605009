/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  TextareaAutosize,
  Button,
  Card,
  MenuItem,
  Select,
  InputLabel,
  Typography,
  Checkbox,
  FormControlLabel,
  Link,
} from '@material-ui/core';
import LoadingButton from '@material-ui/lab/LoadingButton';
import FormControl from '@material-ui/core/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import ImageUploading from 'react-images-uploading';
import { styled } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import Message from '../Message';
import {
  addProductToBackEnd,
  fetchProductCategories,
  updateProduct,
} from '../../actions/productAction';

import client from '../../ipfs';
import { deployMyStore, signMyItem } from '../../actions/lazyFactoryAction';
import { updateUserStore } from '../../actions/userAction';
import { DEPLOY_MY_STORE_RESET } from '../../constants/lazyFactoryConstant';
import { fetchMarketPlace } from '../../actions/marketPlaceAction';

const CssTextField = styled(TextField)({
  margin: 10,
  '& label.Mui-focused': {
    color: '#8338ec',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 10,
    },
    '&.Mui-focused fieldset': {
      borderColor: '#8338ec',
    },
  },
});

const AddProduct = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [storeName, setStoreName] = useState('');
  const [title, setTitle] = useState('test');
  const [description, setDescription] = useState('test');
  const [identifierNum, setIdentifierNum] = useState('test');
  const [identifier, setIdentifier] = useState('test');
  const [grade, setGrade] = useState('test');
  const [gradingCompany, setGradingCompany] = useState('test');
  const [categoryId, setCategoryId] = useState('');
  const [subCategoryId, setSubCategoryId] = useState('');
  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [price, setPrice] = useState();
  const [images, setImages] = useState([[]]);
  const [imageUrl, setImageUrl] = useState('');
  const [address1, setAddress1] = useState(
    '0x0000000000000000000000000000000000000000'
  );
  const [address2, setAddress2] = useState(
    '0x0000000000000000000000000000000000000000'
  );
  const [address3, setAddress3] = useState(
    '0x0000000000000000000000000000000000000000'
  );
  const [address4, setAddress4] = useState(
    '0x0000000000000000000000000000000000000000'
  );
  const [address5, setAddress5] = useState(
    '0x0000000000000000000000000000000000000000'
  );
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [signChecked, setSignChecked] = React.useState(false);
  // const [tokenId, setTokenId] = useState(0);
  const [tokenUri, setTokenUri] = useState('');
  const [signerContractAddress, setSignerContractAddress] = useState('');
  const [deployedContract, setDeployedContract] = useState();
  const [factory, setFactory] = useState();
  const maxNumber = 3;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const categories = useSelector((state) => state.categories);
  const { theCategories, success: successCategories } = categories;

  const productUpdate = useSelector((state) => state.productUpdate);
  const { result, success: successProductUpdate } = productUpdate;

  const myVoucher = useSelector((state) => state.myVoucher);
  const {
    voucher,
    signerAddress,
    loading: loadingSignature,
    success: successSignature,
  } = myVoucher;

  const theMarketPlace = useSelector((state) => state.theMarketPlace);
  const { marketPlace } = theMarketPlace;

  // create product Reducer
  const justAddedBackEnd = useSelector((state) => state.justAddedBackEnd);
  const {
    theProduct,
    loading: loadingAddedBackEnd,
    error: errorAddedBackEnd,
    success: successAddedBackEnd,
  } = justAddedBackEnd;

  // store
  const myStore = useSelector((state) => state.myStore);
  const {
    BLOCKCHAIN,
    loading: loadingMyStore,
    success: successMyStore,
    error: errorMyStore,
  } = myStore;

  // login
  useEffect(() => {
    console.log(address4);
    if (!userInfo) {
      history.push('/login');
    }
  }, [userInfo, address4]);

  // redirect after success
  useEffect(async () => {
    if (successAddedBackEnd && successSignature && successProductUpdate) {
      history.push('/main/products');
    }
  }, [
    successAddedBackEnd,
    successSignature,
    successProductUpdate,
    successMyStore,
  ]);

  // loading button
  useEffect(() => {
    if (loadingAddedBackEnd || loadingMyStore) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [loadingAddedBackEnd, loadingMyStore]);

  // disable button when form is not filled
  useEffect(() => {
    if (!title || !description || !imageUrl || !price || successSignature) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [title, description, imageUrl, price, successSignature]);

  // fetch categories & subCategories
  useEffect(() => {
    if (!successCategories) {
      dispatch(fetchProductCategories());
    } else {
      setAllCategories(theCategories.categories);
    }
  }, [successCategories]);

  // IPFS
  const handleIpfsUpload = async () => {
    if (client) {
      try {
        const added = await client.add(images[0].file, {
          progress: (prog) => console.log(`received: ${prog}`),
        });
        const url = `${process.env.REACT_APP_IPFS}/ipfs/${added.path}`;
        setImageUrl(url);
      } catch (e) {
        console.log(e);
      }
    }
  };

  // tokenUri
  useEffect(async () => {
    if (imageUrl) {
      // create the tokenURI
      const data = JSON.stringify({
        title,
        categoryId,
        subCategoryId,
        // description,
        // identifier,
        // identifierNum,
        // grade,
        // Number(categoryId),
        // tags,
        price,
        imageUrl,
      });
      const added = await client.add(data);
      const url = `${process.env.REACT_APP_IPFS}/ipfs/${added.path}`;
      setTokenUri(url);
    }
  }, [successMyStore, signChecked, imageUrl]);

  // symbol
  useEffect(async () => {
    if (successMyStore && BLOCKCHAIN.factory) {
      try {
        const symbol = await BLOCKCHAIN.factory.symbol();
        console.log(`symbol: ${symbol}`);
      } catch (e) {
        console.log(`Error: ${e}`);
      }
    }
  }, [successMyStore]);

  // contract address and factory
  useEffect(() => {
    if (userInfo && userInfo.store_address) {
      setSignerContractAddress(userInfo.store_address);
    } else if (successMyStore) {
      setDeployedContract(BLOCKCHAIN.signerContract);
      setSignerContractAddress(BLOCKCHAIN.signerContract.address);
      setFactory(BLOCKCHAIN.signerFactory);
    }
  }, [successMyStore, userInfo, signChecked]);

  // update user store contract if not available
  useEffect(() => {
    if (userInfo && !userInfo.store_address && signerContractAddress) {
      dispatch(updateUserStore(signerContractAddress));
    }
  }, [signerContractAddress]);

  // sign + update product
  useEffect(() => {
    if (!successSignature && !loadingSignature && successAddedBackEnd) {
      dispatch(
        signMyItem(
          signerContractAddress,
          theProduct.id,
          price,
          tokenUri,
          address1,
          address2,
          address3,
          address4,
          address5
        )
      );
    }
    if (successSignature) {
      // add signature backend - storeAddress = false / walletAddress = false
      dispatch(
        updateProduct(
          theProduct,
          false,
          signerAddress,
          false,
          voucher,
          'Signing',
          address1,
          address2,
          address3,
          address4,
          address5
        )
      );
    }
  }, [successSignature, loadingSignature, theProduct]);

  // checkBox
  const handleChange = (event) => {
    setSignChecked(event.target.checked);
  };

  const onImageChange = async (imageList) => {
    if (imageList[0]) {
      setImages(imageList);
    }
  };

  // category
  const handleCategorySelect = (e) => {
    const catId = e.target.value;
    setCategoryId(e.target.value);
    const theResult = allCategories.filter((cat) => cat.id === catId);
    setAllSubCategories(theResult[0].sub_categories);
  };

  const handleSubCategorySelect = (e) => {
    setSubCategoryId(e.target.value);
  };

  // my Store deployment
  const handleStoreDeployment = async () => {
    await dispatch({ type: DEPLOY_MY_STORE_RESET }); // in case deployment failed
    await dispatch(fetchMarketPlace());
    console.log(marketPlace.contract);
    dispatch(deployMyStore(marketPlace.contract, storeName));
  };

  // handle signature
  const handleSignature = async (e) => {
    e.preventDefault();
    if (!userInfo) {
      history.push('/login');
    } else {
      dispatch(
        addProductToBackEnd(
          title,
          categoryId,
          subCategoryId,
          // description,
          // identifier,
          // identifierNum,
          // grade,
          // Number(categoryId),
          // tags,
          price,
          imageUrl,
          // holderWalletAddress,
          // tokenTxn,
          // BLOCKCHAIN.factory.address,
          // tokenId,
          address1,
          address2,
          address3,
          address4,
          address5
        )
      );
    }
  };

  const handleSubmit = async (e) => {
    console.log('addredss1');
    console.log(address1);
    e.preventDefault();
    dispatch(
      addProductToBackEnd(
        title,
        categoryId,
        subCategoryId,
        // description,
        // identifier,
        // identifierNum,
        // grade,
        // Number(categoryId),
        // tags,
        price,
        imageUrl,
        address1,
        address2,
        address3,
        address4,
        address5
      )
    );
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      maxWidth
    >
      {userInfo && !userInfo.store_address ? (
        <Grid item sx={{ margin: 10, textAlign: 'center' }}>
          <TextField
            color="secondary"
            value={storeName}
            type="text"
            onChange={(e) => setStoreName(e.target.value)}
            label="Permanent Store Name"
            sx={{ margin: 1 }}
          />
          <LoadingButton
            variant="contained"
            color="primary"
            // disabled={isDisabled}
            loading={isLoading}
            onClick={handleStoreDeployment}
          >
            Creaete My Store
          </LoadingButton>
        </Grid>
      ) : (
        <>
          <Typography variant="subtitle2">
            Store Address:
            <Link
              href={`https://rinkeby.etherscan.io/address/${signerContractAddress}`}
              target="blank"
            >
              {signerContractAddress}
            </Link>
          </Typography>

          <FormControl
            onSubmit={signChecked ? handleSignature : handleSubmit}
            variant="outlined"
            sx={{ width: '100%', marginTop: 3 }}
          >
            <form>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                item
              >
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {successCategories && (
                    <Grid item xs={5} sx={{ width: '50%', margin: 1 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Category"
                          value={categoryId}
                          onChange={handleCategorySelect}
                        >
                          {allCategories.map((theCategory, index) => (
                            <MenuItem key={index} value={theCategory.id}>
                              <Typography>{theCategory.name}</Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {successCategories && (
                    <Grid item xs={5} sx={{ width: '50%', margin: 1 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Sub Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Category"
                          value={subCategoryId}
                          onChange={handleSubCategorySelect}
                        >
                          {allSubCategories.map((theSubCategory, index) => (
                            <MenuItem key={index} value={theSubCategory.id}>
                              <Typography>{theSubCategory.name}</Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    label="title"
                    variant="outlined"
                    value={title || ''}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextareaAutosize
                    aria-label="Description"
                    minRows={3}
                    placeholder="Description"
                    style={{ width: '100%' }}
                    value={description || ''}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <ImageUploading
                    value={images}
                    onChange={onImageChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      isDragging,
                      dragProps,
                    }) => (
                      <Grid className="upload__image-wrapper">
                        {!imageList[0] && (
                          <Button
                            variant="outlined"
                            style={isDragging ? { color: 'red' } : null}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Upload Image
                          </Button>
                        )}
                        &nbsp;
                        {imageList.map((theImage, index) => (
                          <Grid key={index}>
                            <Card
                              elevation={5}
                              sx={{ marginLeft: 5, marginRight: 5 }}
                            >
                              <img
                                src={theImage.data_url || '/images/default.jpg'}
                                alt=""
                                width="60%"
                                style={{ maxHeight: 350 }}
                              />
                            </Card>

                            <Grid className="image-item__btn-wrapper">
                              <Button
                                variant="outlined"
                                onClick={() => onImageUpdate(index)}
                                sx={{ marginTop: 2 }}
                              >
                                Replace
                              </Button>
                              <Button
                                disabled={!!imageUrl} // imageUrl ? true : false
                                variant="outlined"
                                onClick={() => handleIpfsUpload()}
                                sx={{ marginTop: 2 }}
                              >
                                Upload to IPFS
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </ImageUploading>
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    label="Identifier"
                    variant="outlined"
                    value={identifier || ''}
                    onChange={(e) => setIdentifier(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    label="Identifier Num"
                    variant="outlined"
                    value={identifierNum || ''}
                    onChange={(e) => setIdentifierNum(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    label="Grade"
                    variant="outlined"
                    value={grade || ''}
                    onChange={(e) => setGrade(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    label="Grading company"
                    variant="outlined"
                    value={gradingCompany || ''}
                    onChange={(e) => setGradingCompany(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    label="Tags"
                    variant="outlined"
                    value={tags || ''}
                    onChange={(e) => setTags(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    type="number"
                    label="Price in Ether"
                    variant="outlined"
                    value={price || ''}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    type="string"
                    label="address 1"
                    variant="outlined"
                    value={address1 || ''}
                    onChange={(e) => setAddress1(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    type="string"
                    label="address 2"
                    variant="outlined"
                    value={address2 || ''}
                    onChange={(e) => setAddress2(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    type="string"
                    label="address 3"
                    variant="outlined"
                    value={address3 || ''}
                    onChange={(e) => setAddress3(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    type="string"
                    label="address 4"
                    variant="outlined"
                    value={address4 || ''}
                    onChange={(e) => setAddress4(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    type="string"
                    label="address 5"
                    variant="outlined"
                    value={address5 || ''}
                    onChange={(e) => setAddress5(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ marginTop: 4, marginBottom: 10, textAlign: 'center' }}
              >
                <FormControlLabel
                  label="Should We Sell This?"
                  control={
                    <Checkbox
                      checked={signChecked}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                />
                <LoadingButton
                  variant="contained"
                  color="primary"
                  disabled={isDisabled}
                  loading={isLoading}
                  type="submit"
                >
                  {signChecked ? 'Sign to Sell' : 'Add to Gallery'}
                </LoadingButton>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                {(errorAddedBackEnd || errorMyStore) && (
                  <Message
                    backError={errorAddedBackEnd || errorMyStore}
                    variant="filled"
                    severity="error"
                  />
                )}
              </Grid>
            </form>
          </FormControl>
        </>
      )}
    </Grid>
  );
};

export default AddProduct;
