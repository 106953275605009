import { combineReducers } from '@reduxjs/toolkit';
import {
  userByIdReducer,
  userLoginReducer,
  userRegisterReducer,
  userStepReducer,
  userUpdateStoreReducer,
} from './userReducer';

import {
  productListReducer,
  productReducer,
  productBackEndAddReducer,
  productCategoriesReducer,
  productUpdateReducer,
  myProductsReducer,
} from './productReducer';
import cartReducer from './cartReducer';

import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
} from './orderReducer';
import {
  mintAndRedeemReducer,
  storeBalanceReducer,
  storeDeployReducer,
  marketRoyaltyListReducer,
  storeWithdrawReducer,
  voucherReducer,
} from './lazyFactoryReducer';
import {
  MarketBalanceReducer,
  marketPlaceReducer,
  marketPlaceDeployReducer,
  walletConnectionReducer,
  marketPlaceAddReducer,
  ethPriceReducer,
  royaltiesReducer,
  MarketPurchaseReducer,
} from './marketPlaceReducer';

export default combineReducers({
  verifyStep: userStepReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userById: userByIdReducer,
  userStoreAddress: userUpdateStoreReducer, // store address added to the model
  categories: productCategoriesReducer,
  myProductsList: myProductsReducer,
  productList: productListReducer,
  theProduct: productReducer,
  productUpdate: productUpdateReducer,
  theCart: cartReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  justAddedBackEnd: productBackEndAddReducer,
  marketPlaceDeployment: marketPlaceDeployReducer,
  theMarketPlace: marketPlaceReducer,
  marketPlaceBalance: MarketBalanceReducer,
  myStore: storeDeployReducer,
  myStoreBalance: storeBalanceReducer,
  myStoreWithdraw: storeWithdrawReducer,
  marketRoyaltyList: marketRoyaltyListReducer,
  myVoucher: voucherReducer,
  walletConnection: walletConnectionReducer,
  JustPurchased: MarketPurchaseReducer,
  // justMintedNFT: NftMintReducer,
  addedToMarket: marketPlaceAddReducer,
  ETH: ethPriceReducer,
  redeemedMinted: mintAndRedeemReducer,
  tokenRoyalties: royaltiesReducer,
  // myPurchasedList: myPurchasedNFTReducer,
});
