import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Splash from './pages/Splash';
import Register from './pages/auth/Register';
import Login from './pages/auth/Login';
import Main from './pages/Main';
import Product from './pages/Product';
import Shipping from './pages/Shipping';

const App = () => (
  <>
    <BrowserRouter>
      <CssBaseline />
      <React.StrictMode>
        <Switch>
          <Route exact path="/" component={Splash} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/main" component={Main} />
          <Route exact path="/main/products" component={Main} />
          <Route exact path="/main/profile" component={Main} />
          <Route exact path="/main/add" component={Main} />
          <Route exact path="/main/notification" component={Main} />
          <Route exact path="/main/products/:itemId" component={Product} />
          <Route path="/main/products/shipping/:itemId" component={Shipping} />
        </Switch>
      </React.StrictMode>
    </BrowserRouter>
  </>
);
export default App;
