import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_RESET,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_RESET,
  PRODUCT_ADD_REQUEST,
  PRODUCT_ADD_SUCCESS,
  PRODUCT_ADD_FAIL,
  PRODUCT_ADD_RESET,
  PRODUCT_CATAGORIES_REQUEST,
  PRODUCT_CATAGORIES_SUCCESS,
  PRODUCT_CATAGORIES_FAIL,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_RESET,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  MY_PRODUCTS_REQUEST,
  MY_PRODUCTS_SUCCESS,
  MY_PRODUCTS_FAIL,
  MY_PRODUCTS_RESET,
} from '../constants/productConstant';

export const myProductsReducer = (state = {}, action) => {
  switch (action.type) {
    case MY_PRODUCTS_REQUEST:
      return { loading: true };
    case MY_PRODUCTS_SUCCESS:
      return { loading: false, products: action.payload };
    case MY_PRODUCTS_FAIL:
      return { loading: false, error: action.payload };
    case MY_PRODUCTS_RESET:
      return {};
    default:
      return state;
  }
};

export const productListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products_list,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case PRODUCT_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case PRODUCT_LIST_RESET:
      return { products: [] };
    default:
      return state;
  }
};

export const productReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_DETAILS_SUCCESS:
      return {
        loading: false,
        success: true,
        product: action.payload.the_product,
      };
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_DETAILS_RESET:
      return { product: {} };
    default:
      return state;
  }
};

export const productUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        result: action.payload,
      };
    case PRODUCT_UPDATE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    case PRODUCT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productBackEndAddReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_ADD_REQUEST:
      return { loading: true };
    case PRODUCT_ADD_SUCCESS:
      return { loading: false, success: true, theProduct: action.payload };
    case PRODUCT_ADD_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const productCategoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CATAGORIES_REQUEST:
      return { loading: true };
    case PRODUCT_CATAGORIES_SUCCESS:
      return { loading: false, success: true, theCategories: action.payload };
    case PRODUCT_CATAGORIES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
