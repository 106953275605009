import React, { useState, useEffect } from 'react';
import ImageList from '@material-ui/core/ImageList';
import {
  ImageListItem,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchAllProducts, updateProduct } from '../../actions/productAction';
import { mintAndRedeem } from '../../actions/lazyFactoryAction';
import { SIGN_MY_ITEM_RESET } from '../../constants/lazyFactoryConstant';
import {
  connectWallet,
  deployMarketPlace,
  fetchEthPrice,
  fetchMarketPlace,
  purchaseNFT,
} from '../../actions/marketPlaceAction';
import { MARKET_ETH_PRICE_RESET } from '../../constants/marketPlaceConstant';

function srcset(image, width, height, rows = 1, cols = 1) {
  return `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format 1x,
    ${image}?w=${width * cols}&h=${
    height * rows * 1.25
  }&fit=crop&auto=format&dpr=2 2x`;
}

export default function ProductsList() {
  const dispatch = useDispatch();

  const [theProduct, setTheProduct] = useState();
  const [EthPrice, setEthPrice] = useState();
  const [view, setView] = useState('module');

  // back-end products
  const productList = useSelector((state) => state.productList);
  const { products } = productList;

  // back-end products
  const redeemedMinted = useSelector((state) => state.redeemedMinted);
  const { success: successRedeemed, loading: loadingRedeemed } = redeemedMinted;

  // back-end products
  const walletConnection = useSelector((state) => state.walletConnection);
  const { wallet, success: successWallet } = walletConnection;

  const marketPlaceDeployment = useSelector(
    (state) => state.marketPlaceDeployment
  );
  const { success: successBlockchain } = marketPlaceDeployment;

  const theMarketPlace = useSelector((state) => state.theMarketPlace);
  const { marketPlace } = theMarketPlace;

  const ETH = useSelector((state) => state.ETH);
  const { result } = ETH;

  // ETH price
  useEffect(() => {
    if (!result) {
      dispatch(fetchEthPrice());
    } else {
      setEthPrice(result.ethereum.cad);
      return () => {
        setTheProduct(null);
        setEthPrice(0);
        dispatch({ type: MARKET_ETH_PRICE_RESET });
        dispatch(fetchEthPrice());
      };
    }
  }, [dispatch]);

  // purchased a signed token to be minted
  useEffect(() => {
    if (successRedeemed && theProduct && wallet) {
      // create the NFT token on backend - signature = false
      dispatch(
        updateProduct(
          theProduct,
          theProduct.owner.store_address,
          theProduct.signer_address, // signature signer
          wallet, // buyer
          false,
          'RedeemAndMint'
        )
      );
      dispatch(fetchAllProducts());
    }
    dispatch({ type: SIGN_MY_ITEM_RESET });
  }, [successRedeemed]);

  // fetch backend/SQL items
  useEffect(() => {
    dispatch(fetchMarketPlace());
    dispatch(fetchAllProducts());
  }, [dispatch, successBlockchain]);

  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  // mint
  const handlePurchaseSigned = async (product) => {
    console.log(product);
    setTheProduct(product);
    dispatch(
      mintAndRedeem(
        product.id,
        product.owner.store_address, // owner_by / owner.id to owner object django serializer
        product.voucher,
        product.price,
        product.address1,
        product.address2,
        product.address3,
        product.address4,
        product.address5
      )
    );
  };

  const handlePurchaseItem = (product) => {
    dispatch(
      purchaseNFT(
        '0x9B188a79B9247a13213A2D80eCA1527E1Aa0867f',
        marketPlace.contract,
        product
      )
    );
  };
  return (
    <ImageList
      sx={{
        // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
        transform: 'translateZ(0)',
        minWidth: '100%',
      }}
      gap={1}
    >
      {marketPlace && !marketPlace.contract ? (
        <Button onClick={() => dispatch(deployMarketPlace())}>
          Deploy Market Place
        </Button>
      ) : (
        <>
          <ImageListItem key="Subheader" cols={2} sx={{ marginBottom: 2 }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item>
                <ToggleButtonGroup
                  orientation="horizontal"
                  value={view}
                  exclusive
                  onChange={handleChange}
                >
                  <ToggleButton
                    value="list"
                    aria-label="list"
                    sx={{ border: 'transparent' }}
                  >
                    <ViewListIcon />
                  </ToggleButton>
                  <ToggleButton
                    value="module"
                    aria-label="module"
                    sx={{ border: 'transparent' }}
                  >
                    <ViewModuleIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </ImageListItem>
          {products &&
            products.map((product) => {
              const newCols = view === 'list' ? 2 : 1;
              const newRows = view === 'list' ? 3 : 1;

              return (
                <ImageListItem
                  key={product.id}
                  cols={newCols}
                  rows={newRows}
                  sx={{ margin: 0.5, border: '1px solid lightgrey' }}
                >
                  <ImageListItemBar
                    title={
                      <>
                        <span style={{ margin: 20 }}>
                          <Link
                            style={{
                              position: 'absolute',
                              width: '100%',
                              height: '100%',
                            }}
                            to={`products/${product.id}`}
                          >
                            {product.title}
                          </Link>
                        </span>
                      </>
                    }
                    subtitle={
                      <span style={{ margin: 20 }}>
                        by: {product.owner.firstName} {product.owner.lastName}
                      </span>
                    }
                    position="below"
                  />
                  {product ? (
                    <img
                      srcSet={srcset(
                        `${product.ipfs_cover}`,
                        250,
                        200,
                        newRows,
                        newCols
                      )}
                      alt={product.ifps_cover}
                      loading="lazy"
                    />
                  ) : (
                    <img
                      srcSet={srcset(product.cover, 250, 200, newRows, newCols)}
                      alt={product.title}
                      loading="lazy"
                    />
                  )}

                  <ImageListItemBar
                    sx={{
                      background: '#f3f3f3',
                    }}
                    position="below"
                    actionIcon={
                      <>
                        <IconButton sx={{ fontSize: '0.75rem !important' }}>
                          <ArrowUpwardIcon sx={{ color: 'darkgrey' }} />
                          33
                        </IconButton>
                        <IconButton
                          sx={{ fontSize: '0.75rem !important', right: 0 }}
                        >
                          435
                          <InsertCommentIcon sx={{ color: 'darkgrey' }} />
                        </IconButton>
                        <Grid>
                          <Typography variant="body2">
                            $
                            {(product.price * EthPrice).toLocaleString(
                              undefined,
                              { maximumFractionDigits: 2 }
                            )}
                          </Typography>
                          {product.on_market && !product.is_minted && (
                            <Button
                              onClick={() => handlePurchaseSigned(product)}
                              sx={{
                                fontSize: '1.5rem !important',
                                color: 'red',
                              }}
                            >
                              Mint & Buy
                            </Button>
                          )}
                          {product.on_market && product.is_minted && (
                            <Button
                              onClick={() => handlePurchaseItem(product)}
                              sx={{
                                fontSize: '1.5rem !important',
                                color: 'red',
                              }}
                            >
                              Buy
                            </Button>
                          )}
                        </Grid>
                      </>
                    }
                  />
                </ImageListItem>
              );
            })}
        </>
      )}
    </ImageList>
  );
}
