/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Tab,
  Typography,
  Grid,
  Link,
  IconButton,
  Card,
  Button,
  TextField,
} from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { useDispatch, useSelector } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import {
  fetchStoreBalance,
  storeWithdraw,
} from '../../actions/lazyFactoryAction';
import { logout } from '../../actions/userAction';
import { fetchMyProducts } from '../../actions/productAction';
import {
  createMarketSell,
  fetchMarketBalance,
  fetchMarketPlace,
  fetchRoyalties,
} from '../../actions/marketPlaceAction';
import { weiToEth } from '../../converter';

export default function Profile() {
  const dispatch = useDispatch();

  const [fluPercentage, setFluPercentage] = useState();
  const [chainPercentage, setChainPercentage] = useState();
  const [value, setValue] = useState('1');
  const [newPrice, setNewPrice] = useState('');
  const [inputId, setInputId] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const theMarketPlace = useSelector((state) => state.theMarketPlace);
  const { marketPlace } = theMarketPlace;

  const myStoreBalance = useSelector((state) => state.myStoreBalance);
  const { storeBalance } = myStoreBalance;

  const marketPlaceBalance = useSelector((state) => state.marketPlaceBalance);
  const { marketBalance, success: successMarketBalance } = marketPlaceBalance;

  const myProductsList = useSelector((state) => state.myProductsList);
  const { products, success: successMyProduct } = myProductsList;

  const tokenRoyalties = useSelector((state) => state.tokenRoyalties);
  const { royalties } = tokenRoyalties;

  useEffect(() => {
    if (userInfo && (!successMyProduct || !storeBalance)) {
      dispatch(fetchMarketPlace());
      dispatch(fetchStoreBalance(userInfo.store_address));
      dispatch(fetchMyProducts());
    }
  }, [userInfo, successMarketBalance, dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // calculate user stream
  useEffect(() => {
    if (userInfo) {
      switch (userInfo.level) {
        case 1:
          setFluPercentage(0.2);
          setChainPercentage(0.1);
          return fluPercentage && chainPercentage;
        case 5:
          setFluPercentage(0.4);
          setChainPercentage(0.2);
          return fluPercentage && chainPercentage;
        case 10:
          setFluPercentage(0.6);
          setChainPercentage(0.3);
          return fluPercentage && chainPercentage;
        case 15:
          setFluPercentage(0.8);
          setChainPercentage(0.4);
          return fluPercentage && chainPercentage;
        case 20:
          setFluPercentage(1);
          setChainPercentage(0.5);
          return fluPercentage && chainPercentage;
        case 25:
          setFluPercentage(1);
          setChainPercentage(0.6);
          return fluPercentage && chainPercentage;
        case 30:
          setFluPercentage(1);
          setChainPercentage(0.7);
          return fluPercentage && chainPercentage;
        case 35:
          setFluPercentage(1);
          setChainPercentage(0.8);
          return fluPercentage && chainPercentage;
        case 40:
          setFluPercentage(1);
          setChainPercentage(0.9);
          return fluPercentage && chainPercentage;
        case 45:
          setFluPercentage(1);
          setChainPercentage(1);
          return fluPercentage && chainPercentage;
        default:
          return fluPercentage && chainPercentage;
      }
    }
  }, [userInfo]);

  const handleInputChange = (e) => {
    console.log(e.target.value);
    setNewPrice(e.target.value);
  };

  const handleRoyalties = () => {
    dispatch(fetchRoyalties(marketPlace.contract, inputId));
  };

  const handleMarketBalance = () => {
    if (marketPlace) {
      dispatch(fetchMarketBalance(marketPlace.contract));
    }
  };
  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Store" value="1" />
            <Tab label="Own" value="2" />
            <Tab label="Streams" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <IconButton onClick={() => dispatch(logout())}>
              <LogoutIcon />
            </IconButton>
            {userInfo && (
              <>
                <Typography variant="subtitle2">
                  Profile:
                  {userInfo.firstName} {userInfo.lastName}
                </Typography>

                <Typography variant="body1">
                  Store Address:
                  <Link
                    href={`https://rinkeby.etherscan.io/address/${userInfo.store_address}`}
                    target="blank"
                  >
                    {userInfo.store_address}
                  </Link>
                </Typography>
                <br />
                <Typography variant="subtitle2">
                  Store Balance:
                  {storeBalance}
                </Typography>
                <Grid
                  container
                  maxWidth
                  sx={{ textAlign: 'center', marginTop: 2 }}
                >
                  <Grid item xs={12} sx={{ margin: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() => dispatch(storeWithdraw())}
                    >
                      Store Owner Withdraw
                    </Button>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 5 }}>
                    <Button
                      variant="contained"
                      onClick={() => dispatch(storeWithdraw())}
                    >
                      Payee Withdraw
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <Grid container direction="row">
            {userInfo &&
              products &&
              products.map((product) => (
                <Grid item xs={4} key={product.id} sx={{ padding: 1 }}>
                  <Card>
                    <img
                      src={`${product.ipfs_cover}`}
                      alt={product.title}
                      loading="lazy"
                      style={{ width: '100%' }}
                    />
                    <Typography variant="subtitle2">{product.title}</Typography>
                    <Typography variant="subtitle2">
                      {(!product.on_market ||
                        product.owned_by === userInfo.id) && (
                        <Grid item xs={12}>
                          <TextField
                            value={newPrice}
                            onChange={handleInputChange}
                            label="New Price"
                          />
                          <IconButton
                            onClick={() =>
                              dispatch(
                                createMarketSell(
                                  product,
                                  product.market.contract,
                                  product.NFT.contract,
                                  product.id,
                                  newPrice
                                )
                              )
                            }
                          >
                            <PublishedWithChangesIcon />
                          </IconButton>
                        </Grid>
                      )}
                    </Typography>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </TabPanel>
        <TabPanel value="3">
          {marketPlace && userInfo && (
            <Grid container direction="column">
              <Grid
                item
                sx={{ textAlign: 'center', marginTop: 2, marginBottom: 5 }}
              >
                <Typography variant="body1">
                  MarketPlace: <br />
                  {marketPlace.contract}
                </Typography>
                <Typography variant="subtitle2">
                  Market Balance:
                  {marketBalance}
                </Typography>
                <Button variant="contained" onClick={handleMarketBalance}>
                  Market Balance
                </Button>
              </Grid>
              <Grid item>
                <Card elevation={2} sx={{ padding: 2 }}>
                  <Typography component="span" variant="body1">
                    Potential {' ---> '}
                  </Typography>
                  <Typography component="span" variant="body1">
                    1L:
                  </Typography>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ margin: 1, color: 'green' }}
                  >
                    %{marketPlace.oneL_fee_allocation * 100}
                  </Typography>
                  <Typography component="span" variant="body1">
                    FLU:
                  </Typography>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ margin: 1, color: 'green' }}
                  >
                    %{marketPlace.flu_fee_allocation * 100}
                  </Typography>
                  <Typography component="span" variant="body1">
                    Chain:
                  </Typography>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ margin: 1, color: 'green' }}
                  >
                    %{marketPlace.chain_fee_allocation * 100}
                  </Typography>
                </Card>
              </Grid>
              <Grid item>
                <Card sx={{ marginTop: 1, padding: 2 }}>
                  <Typography component="span" variant="body1">
                    My Level:
                  </Typography>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ margin: 1, color: 'red' }}
                  >
                    {userInfo.level}
                  </Typography>
                  <Typography component="span" variant="body1">
                    FLU:
                  </Typography>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ margin: 1, color: 'red' }}
                  >
                    %{fluPercentage * 100}
                  </Typography>
                  <Typography component="span" variant="body1">
                    Chain:
                  </Typography>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ margin: 1, color: 'red' }}
                  >
                    %{chainPercentage * 100}
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 2 }}>
                <TextField
                  value={inputId}
                  onChange={(e) => setInputId(e.target.value)}
                  label="Enter Token Id"
                />
                <Button variant="contained" onClick={handleRoyalties}>
                  Royalties?
                </Button>
                {royalties &&
                  royalties.map((royalty, index) => (
                    <Grid key={index}>
                      <Typography variant="subtitle2">
                        {royalty.walletAddress}
                        <span style={{ color: 'red' }}>
                          <br />
                          Balance Ether: {weiToEth(royalty.balance)}
                        </span>
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          )}
        </TabPanel>
      </TabContext>
    </Box>
  );
}
