class Royalty {
  constructor({ tokenId, signerAddress }) {
    this.tokenId = tokenId;
    this.signerAddress = signerAddress;
    this.chainStream = {
      1: 0.1,
      5: 0.2,
      10: 0.3,
      15: 0.4,
      20: 0.5,
      25: 0.6,
      30: 0.7,
      35: 0.8,
      40: 0.9,
      45: 1,
    };
    this.fluStream = {
      1: 0.2,
      5: 0.4,
      10: 0.6,
      15: 0.8,
      20: 1,
    };
  }

  async createList(
    marketAddress,
    action,
    ethers,
    fluFeeAllocation,
    chainFeeAllocation,
    fee,
    FluAddress,
    userLevelList, // dict/object // what if one of first 5 is not registered on app with no level??
    oldRoyaltyList
  ) {
    // first 6 royalties added by function newRoyaltyChain with all balances = 0 - MarketPlace.sol
    let fluPotential;
    let fluStreamEther;
    let chainTotalPotential;
    let chianStreamTotal;
    let chainEachPotential;
    let chainStreamEther;
    const newRoyaltyList = [];
    for (let i = 0; i < oldRoyaltyList.length; i += 1) {
      if (action === 'minting') {
        console.log('Creating royalties by minting');
        // oneLPotential = fee * oneLFeeAllocation + fee * chainFeeAllocation;
        // fluPotential = fee * fluFeeAllocation;
        // chainEachPotential = chainTotal / oldRoyaltyList.length;

        // // oneL stream - always 100% stream
        // const oneLNewAmount = oneLPotential * 1;

        // // FLU stream
        // if (FluAddress === oldRoyaltyList[i].walletAddress) {
        //   const userLevelFlu = userLevelList.userWallet;
        //   const fluStream = this.fluStream(userLevelFlu);
        //   const fluNewAmount = fluPotential * fluStream;
        // } else {
        //   // chain stream
        //   const userWallet = oldRoyaltyList[i].walletAddress;
        //   const userLevelChain = userLevelList.userWallet;
        //   const chainMemberStream = this.chainStream(userLevelChain);
        //   const chainMemberNewAmount = chainEachPotential * chainMemberStream;
        // }
      } else if (action === 'addToMarket') {
        console.log('Creating royalties by adding to market');
        // 1- Potentials
        fluPotential = fee * fluFeeAllocation;
        chainTotalPotential = fee * chainFeeAllocation;
        // four decimals for each chain
        chainEachPotential =
          Math.round(
            (chainTotalPotential / (oldRoyaltyList[i].length - 1) +
              Number.EPSILON) *
              100000
          ) / 100000; // Deduct one for FLU
        console.log(chainEachPotential);

        // 2- Streams
        let userLevelFlu;
        let userLevel;
        // backend check the array of users attached to royalties
        for (let l = 0; l < userLevelList.length; l = +1) {
          if (
            FluAddress === oldRoyaltyList[i].walletAddress && // check address is FLU
            FluAddress === userLevelList[l].user_address // check address associated level exists
          ) {
            // FLU stream
            userLevelFlu = userLevelList[l].level;
            const fluStreamPercent = this.fluStream[userLevelFlu];
            fluStreamEther = fluStreamPercent * fluPotential;
            const royalty = {
              walletAddress: oldRoyaltyList[i].walletAddress,
              tokenId: this.tokenId,
              balance: ethers.utils.parseUnits(
                fluStreamEther.toString(),
                'ether'
              ),
            };

            newRoyaltyList.push(royalty);
          } else {
            // Chain stream
            userLevel = userLevelList[l].level;
            const chainStreamPercent = this.chainStream[userLevel];
            chainStreamEther = chainStreamPercent * chainEachPotential;
            chianStreamTotal = +chainStreamEther;

            const royalty = {
              walletAddress: oldRoyaltyList[i].walletAddress,
              tokenId: this.tokenId,
              balance: ethers.utils.parseUnits(
                chainStreamEther.toString(),
                'ether'
              ),
            };

            newRoyaltyList.push(royalty);
          }
        }
      }
    }

    // Add marketPlace to collect OneL fees
    const oneLStreamEther = fee - fluStreamEther - chianStreamTotal;
    const royalty = {
      walletAddress: marketAddress,
      tokenId: this.tokenId,
      balance: ethers.utils.parseUnits(oneLStreamEther.toString(), 'ether'),
    };
    newRoyaltyList.push(royalty);
    console.log(newRoyaltyList);

    return newRoyaltyList;
  }
}

export default Royalty;
