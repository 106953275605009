import { createTheme } from '@material-ui/core/styles';

const theTheme = createTheme({
  palette: {
    background: {
      default: '#fbfbfb', // CSSBaseline is needed for this
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#3d405b',
      // dark: will be calculated from palette.primary.main,
      dark: 'black',
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#f2cc8f',
      main: '#8338ec',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color"s luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
  },

  components: {
    MuiContainer: {
      defaultProps: {
        // disableGutters: true, // If true, the left and right padding is removed.
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle2: {
          fontWeight: 800,
          fontSize: '12px',
          lineHeight: '14px',
        },
        body1: {
          color: '#000000',
          fontWeight: 200,
          fontSize: '10px',
          lineHeight: '14px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        // disableRipple: true, // No more ripple!
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        containedPrimary: {
          minWidth: '140px',
          background: 'black',
          borderRadius: '48px',
          color: 'white',
        },
        outlinedPrimary: {
          minWidth: '180px',
          color: 'black',
        },
        filledPrimary: {
          color: 'white',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: 15,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        // shrink: true,
      },
      styleOverrides: {
        root: {
          // color: '#81b29a',
          fontSize: '0.7rem',
          '&.Mui-focused': {
            // color: '#81b29a',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '50px',
          borderRadius: 4,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          display: 'block',
          margin: 'auto',
          marginTop: 10,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          marginTop: 6,
          fontSize: '0.75rem',
          justifyContent: 'center',
        },
        outlinedError: {
          color: '#f44336',
          borderColor: 'transparent',
        },
        outlinedSuccess: {
          borderColor: 'transparent',
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          background: '#3d405b',
          color: 'white',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: 'white',
          '&.Mui-selected': {
            color: 'white',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '0.65rem',
          padding: 1,
          '&.Mui-selected': {
            fontSize: '0.75rem',
            padding: 1,
          },
        },
      },
    },
  },
});

export default theTheme;
