import * as React from 'react';
import Alert from '@material-ui/core/Alert';
import PropTypes from 'prop-types';

export default function Message({ variant, children, severity }) {
  return (
    <Alert variant={variant} severity={severity}>
      {children}
    </Alert>
  );
}

Message.propTypes = {
  variant: PropTypes.string.isRequired,
  children: PropTypes.string,
  severity: PropTypes.string.isRequired,
};

Message.defaultProps = {
  children: '',
};
